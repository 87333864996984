import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`What do PayPerks and streaming music app Songza have in common? Both have founders with the last name Davich and both companies have a founder that went to Bowdoin.`}</p>
    <p>{`There are many more lesser known facts, and fun photos, in this feature story from the Fall 2013 issue of Bowdoin Magazine.`}</p>
    <p>{`Go U Bears!`}</p>
    <iframe className="scribd_iframe_embed" src="//www.scribd.com/embeds/185281188/content?start_page=1&#038;view_mode=scroll&#038;show_recommendations=true" data-auto-height="false" data-aspect-ratio="undefined" scrolling="no" id="doc_91782" width="100%" height="600" frameBorder="0"></iframe>
    <p><a parentName="p" {...{
        "href": "http://www.scribd.com/doc/185281188/Bowdoin-Magazine-Features-PayPerks%E2%80%99-CEO-and-her-brother"
      }}>{`Bowdoin Magazine Features PayPerks’ CEO and her brother`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      